import Pawn_Dark from '../assets/Chess_pdt60.png'
import Pawn_Light from '../assets/Chess_plt60.png';
import Bishop_Dark from '../assets/Chess_bdt60.png';
import Bishop_Light from '../assets/Chess_blt60.png';
import King_Dark from '../assets/Chess_kdt60.png';
import King_Light from '../assets/Chess_klt60.png';
import Knight_Dark from '../assets/Chess_ndt60.png';
import Knight_Light from '../assets/Chess_nlt60.png';
import Queen_Dark from '../assets/Chess_qdt60.png';
import Queen_Light from '../assets/Chess_qlt60.png';
import Rook_Dark from '../assets/Chess_rdt60.png';
import Rook_Light from '../assets/Chess_rlt60.png';
import Empty from '../assets/Empty.png';

// BOARD VALUES
const boardValues = {
    a8: Rook_Dark,
    a8SquareInfo: 'Rook_Dark',
    b8: Knight_Dark,
    b8SquareInfo: 'Knight_Dark',
    c8: Bishop_Dark,
    c8SquareInfo: 'Bishop_Dark',
    d8: King_Dark,
    d8SquareInfo: 'King_Dark',
    e8: Queen_Dark,
    e8SquareInfo: 'Queen_Dark',
    f8: Bishop_Dark,
    f8SquareInfo: 'Bishop_Dark',
    g8: Knight_Dark,
    g8SquareInfo: 'Knight_Dark',
    h8: Rook_Dark,
    h8SquareInfo: 'Rook_Dark',
    a7: Pawn_Dark,
    a7SquareInfo: 'Pawn_Dark',
    b7: Pawn_Dark,
    b7SquareInfo: 'Pawn_Dark',
    c7: Pawn_Dark,
    c7SquareInfo: 'Pawn_Dark',
    d7: Pawn_Dark,
    d7SquareInfo: 'Pawn_Dark',
    e7: Pawn_Dark,
    e7SquareInfo: 'Pawn_Dark',
    f7: Pawn_Dark,
    f7SquareInfo: 'Pawn_Dark',
    g7: Pawn_Dark,
    g7SquareInfo: 'Pawn_Dark',
    h7: Pawn_Dark,
    h7SquareInfo: 'Pawn_Dark',
    a6: Empty,
    a6SquareInfo: 'Empty',
    b6: Empty,
    b6SquareInfo: 'Empty',
    c6: Empty,
    c6SquareInfo: 'Empty',
    d6: Empty,
    d6SquareInfo: 'Empty',
    e6: Empty,
    e6SquareInfo: 'Empty',
    f6: Empty,
    f6SquareInfo: 'Empty',
    g6: Empty,
    g6SquareInfo: 'Empty',
    h6: Empty,
    h6SquareInfo: 'Empty',
    a5: Empty,
    a5SquareInfo: 'Empty',
    b5: Empty,
    b5SquareInfo: 'Empty',
    c5: Empty,
    c5SquareInfo: 'Empty',
    d5: Empty,
    d5SquareInfo: 'Empty',
    e5: Empty,
    e5SquareInfo: 'Empty',
    f5: Empty,
    f5SquareInfo: 'Empty',
    g5: Empty,
    g5SquareInfo: 'Empty',
    h5: Empty,
    h5SquareInfo: 'Empty',
    a4: Empty,
    a4SquareInfo: 'Empty',
    b4: Empty,
    b4SquareInfo: 'Empty',
    c4: Empty,
    c4SquareInfo: 'Empty',
    d4: Empty,
    d4SquareInfo: 'Empty',
    e4: Empty,
    e4SquareInfo: 'Empty',
    f4: Empty,
    f4SquareInfo: 'Empty',
    g4: Empty,
    g4SquareInfo: 'Empty',
    h4: Empty,
    h4SquareInfo: 'Empty',
    a3: Empty,
    a3SquareInfo: 'Empty',
    b3: Empty,
    b3SquareInfo: 'Empty',
    c3: Empty,
    c3SquareInfo: 'Empty',
    d3: Empty,
    d3SquareInfo: 'Empty',
    e3: Empty,
    e3SquareInfo: 'Empty',
    f3: Empty,
    f3SquareInfo: 'Empty',
    g3: Empty,
    g3SquareInfo: 'Empty',
    h3: Empty,
    h3SquareInfo: 'Empty',
    a2: Pawn_Light,
    a2SquareInfo: 'Pawn_Light',
    b2: Pawn_Light,
    b2SquareInfo: 'Pawn_Light',
    c2: Pawn_Light,
    c2SquareInfo: 'Pawn_Light',
    d2: Pawn_Light,
    d2SquareInfo: 'Pawn_Light',
    e2: Pawn_Light,
    e2SquareInfo: 'Pawn_Light',
    f2: Pawn_Light,
    f2SquareInfo: 'Pawn_Light',
    g2: Pawn_Light,
    g2SquareInfo: 'Pawn_Light',
    h2: Pawn_Light,
    h2SquareInfo: 'Pawn_Light',
    a1: Rook_Light,
    a1SquareInfo: 'Rook_Light',
    b1: Knight_Light,
    b1SquareInfo: 'Knight_Light',
    c1: Bishop_Light,
    c1SquareInfo: 'Bishop_Light',
    d1: King_Light,
    d1SquareInfo: 'King_Light',
    e1: Queen_Light,
    e1SquareInfo: 'Queen_Light',
    f1: Bishop_Light,
    f1SquareInfo: 'Bishop_Light',
    g1: Knight_Light,
    g1SquareInfo: 'Knight_Light',
    h1: Rook_Light,
    h1SquareInfo: 'Rook_Light'
}

export default boardValues;